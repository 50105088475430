var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "modules" },
    [
      _c("h1", { staticClass: "heading" }, [_vm._v("Form modules")]),
      _c("p", { staticClass: "paragraph" }, [
        _vm._v(
          "Using components with form elements. And emitting their value to the parent."
        )
      ]),
      _c(
        "FormRow",
        {
          attrs: { coupon: _vm.coupon },
          on: {
            input: function($event) {
              _vm.coupon = $event
            }
          }
        },
        [_vm._v(" Coupon code ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }