var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-row" }, [
    _c("label", { staticClass: "form-row__label" }, [_vm._t("default")], 2),
    _c("div", { staticClass: "fields" }, [
      _c("input", {
        staticClass: "form-row__input-text",
        attrs: { type: "text" },
        domProps: { value: _vm.coupon },
        on: {
          input: function($event) {
            return _vm.updateCode($event.target.value)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }